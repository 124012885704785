<template>
  <v-dialog
    v-model="isOpen"
    max-width="400px"
  >
    <v-card v-if="isOpen">
      <v-card-title class="font-weight-bold d-flex justify-space-between">
        <span>Buat Laporan Transaksi</span>
        <v-icon
          size="26px"
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="reportForm"
          lazy-validation
          class="d-flex flex-column"
          @submit.prevent
        >
          <div>
            <div class="text-caption-2 mb-1">
              Filter Tanggal
            </div>
            <DateRangePicker
              ref="dateRangePicker"
              v-model="filterDate"
              class="mb-4"
              :max-date="today"
              :rules="[required]"
            />
          </div>
          <div>
            <div class="text-caption-2 mb-1">
              Tipe Subskripsi
            </div>
            <v-select
              v-model="subscriptionType"
              :items="typeOptions"
              item-text="label"
              item-value="value"
              outlined
              dense
              flat
              hide-details="auto"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="generateLaporan"
        >
          Buat Laporan
        </v-btn>
      </v-card-actions>
    </v-card>
    <pdf-viewer
      ref="pdf"
    />
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import PdfViewer from '@/components/misc/PdfViewer.vue'
import Vue from 'vue'
import { required } from '@core/utils/validation'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    DateRangePicker,
    PdfViewer,
  },
  setup() {
    const pdf = ref(null)
    const isOpen = ref(false)
    const today = new Date()
    const subscriptionType = ref(null)
    const show = () => {
      isOpen.value = true
    }
    const filterDate = ref({ startDate: null, endDate: null })

    const close = () => {
      isOpen.value = false
      filterDate.value = { startDate: null, endDate: null }
      subscriptionType.value = null
    }

    const typeOptions = [
      { label: 'Semua', value: null },
      { label: 'Lite Plan', value: 2 },
      { label: 'Pro Plan', value: 3 },
      { label: 'Pro+ Plan', value: 4 },
      { label: 'Enterprise Plan', value: 5 },
    ]

    const reportForm = ref(null)

    const generateLaporan = () => {
      console.log(reportForm.value.validate() && filterDate.value.endDate && filterDate.value.startDate)
      if (reportForm.value.validate()) {
        if (filterDate.value.endDate && filterDate.value.startDate) {
          pdf.value.show(`${Vue.prototype.$restHTTP}/rest/laporan-transaksi?date_min=${filterDate.value.startDate}&date_max=${filterDate.value.endDate}&type=${subscriptionType.value}`, 'Laporan Penjualan Oriens')
        } else {
          errorHandling('Tanggal tidak boleh kosong')
        }
      }
    }

    return {
      isOpen,
      show,
      close,
      icons: {
        mdiClose,
      },
      today,
      filterDate,
      subscriptionType,
      typeOptions,
      pdf,
      required,
      generateLaporan,
      reportForm,
    }
  },
}
</script>
