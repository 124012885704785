<template>
  <div>
    <v-card>
      <div class="d-flex justify-space-between ms-5 pt-5 pb-1">
        <div><h3>Riwayat Transaksi Pengguna</h3></div>
        <v-btn
          color="primary"
          class="me-4"
          @click="$refs.transactionReportForm.show()"
        >
          Download Laporan
        </v-btn>
      </div>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-row class="mb-1">
          <v-col
            cols="12"
            md="4"
          >
            <div class="text-caption-2 mb-1">
              Filter Tanggal
            </div>
            <DateRangePicker
              ref="dateRangePicker"
              v-model="filterDate"
              class="mb-4"
              :max-date="today"
              @update="changeFilterDate"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="text-caption-2 mb-1">
              Filter Status
            </div>
            <v-select
              v-model="filterTransaction.status_id"
              :items="statusOptions"
              item-text="label"
              item-value="value"
              outlined
              dense
              flat
              hide-details="auto"
              @change="fetchTransaction"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="text-caption-2 mb-1">
              Tipe Subskripsi
            </div>
            <v-select
              v-model="filterTransaction.subscription_type_id"
              :items="typeOptions"
              item-text="label"
              item-value="value"
              outlined
              dense
              flat
              hide-details="auto"
              @change="fetchTransaction"
            />
          </v-col>
        </v-row>
        <v-chip
          v-if="filterTransaction.status_id || filterTransaction.subscription_type_id || filterTransaction.date_max"
          class="mb-4"
          small
          close
          color="error"
          @click:close="resetFilter"
        >
          Reset Filter
        </v-chip>
        <v-data-table
          item-key="id"
          height="53vh"
          fixed-header
          class="mx-5"
          :headers="tableColumns"
          :items="userTransactionList"
          mobile-breakpoint="0"
          :server-items-length="userTransactionCount"
          disable-sort
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
            'items-per-page-text': 'Jumlah data',
          }"
          :options.sync="transactionPagination"
          :loading="loadingUserTransactionList"
        >
          <template v-slot:[`item.midtrans_order_id`]="{ item }">
            <div class="d-flex py-2">
              {{ item.midtrans_order_id }}
            </div>
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <router-link
              :to="{
                name: 'admin-user-detail',
                params: {
                  id: item.user.id,
                },
              }"
            >
              <div>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      class="v-avatar-light-bg"
                      size="36"
                      v-on="on"
                    >
                      <v-img
                        v-if="item.user.photo"
                        :src="item.user.photo"
                      />
                      <span v-else>{{ avatarText(item.user.name) }}</span>
                    </v-avatar>
                  </template>
                  <span>{{ item.user.name }}</span>
                </v-tooltip>
                <span class="ms-2">{{ item.user.name }}</span>
              </div>
            </router-link>
          </template>
          <template v-slot:[`item.status`]="props">
            <div class="d-flex py-2">
              <v-chip
                :color="resolveStatusColor(props.item.status.name)"
                outlined
                class="text-capitalize"
              >
                {{ props.item.status.name }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.payment_type`]="props">
            <div class="d-flex py-2">
              <v-chip
                v-if="props.item.payment_type"
                :color="props.item.payment_type.color"
                outlined
                class="text-uppercase"
              >
                {{ props.item.payment_type ? props.item.payment_type.name : '' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="props">
            <div class="d-flex py-2">
              {{ formatDate(props.item.created_at, { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }) }}
            </div>
          </template>
          <template v-slot:[`item.gross_amount`]="props">
            <div class="d-flex py-2">
              {{ formatCurrency(props.item.gross_amount) }}
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <transaction-report-form ref="transactionReportForm" />
  </div>
</template>
<script>
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import { onMounted, ref, watch } from '@vue/composition-api'
import useUser from '@/composables/useUser'
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import TransactionReportForm from '@/views/admin/TransactionReportForm.vue'

export default {
  components: {
    DateRangePicker,
    TransactionReportForm,
  },
  setup() {
    const {
      fetchUserTransactionList, userTransactionCount, loadingUserTransactionList, userTransactionList,
    } = useUser()
    const dateRangePicker = ref(null)
    const today = new Date()
    const filterDate = ref({ startDate: null, endDate: null })
    const statusOptions = [
      { label: 'Semua', value: null },
      { label: 'Success', value: 2 },
      { label: 'Failure', value: 3 },
      { label: 'Pending', value: 4 },
    ]

    const resolveStatusColor = status => {
      if (status === 'pending') return 'secondary'
      if (status === 'success') return 'success'
      if (status === 'failure') return 'error'
      if (status === 'challenge') return 'warning'

      return ''
    }

    const tableColumns = ref([
      {
        text: 'ID',
        value: 'midtrans_order_id',
      },
      {
        text: 'Dibuat Oleh',
        value: 'user',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Metode Pembayaran',
        value: 'payment_type',
      },
      {
        text: 'Tanggal transaksi',
        value: 'created_at',
      },
      {
        text: 'Jumlah',
        value: 'gross_amount',
      },
      // {
      //   text: 'Aksi',
      //   value: 'action',
      // },
    ])

    const typeOptions = [
      { label: 'Semua', value: null },
      { label: 'Lite Plan', value: 2 },
      { label: 'Pro Plan', value: 3 },
      { label: 'Pro+ Plan', value: 4 },
      { label: 'Enterprise Plan', value: 5 },
    ]

    const filterTransaction = ref({
      status_id: null,
      subscription_type_id: null,
    })

    const transactionPagination = ref({
      page: 1,
      itemsPerPage: 10,
    })

    const fetchTransaction = () => {
      fetchUserTransactionList(
        {
          limit: transactionPagination.value.itemsPerPage ? transactionPagination.value.itemsPerPage : 10,
          offset: transactionPagination.value.page && (transactionPagination.value.page - 1) * transactionPagination.value.itemsPerPage,
        },
        filterTransaction.value,
      )
    }

    onMounted(() => {
      fetchTransaction()
    })

    const changeFilterDate = val => {
      filterTransaction.value = {
        ...filterTransaction.value,
        date_min: val.startDate ? val.startDate.toISOString() : null,
        date_max: val.endDate ? val.endDate.toISOString() : null,
      }
      fetchTransaction()
    }

    const resetFilter = () => {
      filterTransaction.value = {
        status_id: null,
        subscription_type_id: null,
      }
      filterDate.value = { startDate: null, endDate: null }
      dateRangePicker.value.reset()
      fetchTransaction()
    }

    watch(transactionPagination, () => {
      fetchTransaction()
    })

    return {
      today,
      statusOptions,
      filterTransaction,
      typeOptions,
      changeFilterDate,
      userTransactionCount,
      loadingUserTransactionList,
      userTransactionList,
      resetFilter,
      dateRangePicker,
      filterDate,
      fetchTransaction,
      tableColumns,
      transactionPagination,
      resolveStatusColor,
      formatDate,
      formatCurrency,
      avatarText,
    }
  },
}
</script>
